.tags-searching {
    margin-top: 32px;
    padding-right: 20px;
}

.tags-searching-title {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4D5163;
}

.tags-search-input {
    width: 100%;
    max-width: 750px;
    height: 43px;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #D9DDE6;
    outline: none;
}

.tags-button-block {
    display: flex;
    justify-content: center;
}
.tags-button {
    margin: 3px 0;
    background-color: unset;
    padding: 5px 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    height: 41px;
    color: #0ca;
    font-family: Montserrat, sans-serif;
    font-style: italic;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
}
@media screen and (min-width: 1700px) {
    .tags-searching {
        padding-right: unset;
    }
    .tags-search-input {
        max-width: 910px;
    }
}