.container-range {
    display: flex;
    align-items: center;
    margin: 70px 35px 0;
    position: relative;
}




.slider {
    position: relative;
    width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
}

.slider__range {
    background-color: #0ca;
    z-index: 2;
}

.slider__left-value,
.slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
}

.slider__left-value {
    color: #4D5163;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    line-height: 22px;
    border-radius: 5px;
    padding: 5px;
    left: 6px;
    top: -68px;
}

.slider__right-value {
    color: #4D5163;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    line-height: 22px;
    border-radius: 5px;
    padding: 5px;
    right: -6px;
    top: -68px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    /*max-width: 750px;*/
    outline: none;
}
@media screen and (max-width: 1199px) {
    .thumb {
        width: 100%;
    }
}
.thumb--zindex-3 {
    z-index: 3;
}

.thumb--zindex-4 {
    z-index: 4;
}

.thumb--zindex-5 {
    z-index: 5;
}
.input-range__track--active {
    background: #0ca!important;
}
.input-range__slider {
    background: #0ca!important;
    border: 1px solid #0ca!important;
}
/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

@media screen and (max-width: 490px) {
    .container {
        max-width: 350px;
    }
    .thumb {
        max-width: 335px;
    }
}
@media screen and (max-width: 380px) {
    .container {
        max-width: 315px;
    }
    .thumb {
        max-width: 301px;
    }
}
