
.area-container {
    display: flex;
}

.area-params {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    max-width: 780px;
}

.area-params-title {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4D5163;
}

.area-params-block {
    margin-right: 10px;
    max-width: 345px;
    flex-grow: 1;
}
.area-input-block {
    display: flex;
    margin-top: 4px;
}

.area-input {
    max-width: 50%;
    z-index: 0;
    height: 41px;
    border: 1px solid #D9DDE6;
    outline: none;
}

.left-input {
    border: 1px solid #D9DDE6;
    border-radius: 4px 0 0 4px;
}

.area-inputs {
    display: flex;
    max-width: 200px;
    margin-top: 16px;
}
.right-input {
    border: 1px solid #D9DDE6;
    border-left: unset;
    border-radius: 0 4px 4px 0;
}
@media screen and (min-width: 1700px) {
    .area-input-block {
        justify-content: space-between;
    }
    .area-input {
        max-width: unset;
    }
    .area-params-block {
        margin-right: unset;
    }
}
@media screen and (min-width: 1201px) {
    .area-input {
        max-width: 77%;
    }
}
@media screen and (max-width: 1199px) {
    .area-params {
        max-width: 574px;
    }
    .area-params-block {
        max-width: 255px;
    }
}
@media screen and (max-width: 760px) {
    .area-params {
        flex-direction: column;
    }
    .area-params-block {
        margin-top: 10px;
    }
    .area-input {
        max-width: unset;
    }
}
@media screen and (max-width: 581px) {
    .area-input {
        max-width: 50%;
    }
    .area-params {
        margin-top: 22px;
    }
    .area-params-block {
        margin-top: 10px;
    }
    .room-params {
        margin-top: unset;
    }
    .margin-class {
        margin-top: 10px;
    }
}