.filter-block {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    min-height: 100vh
}

@media screen and (max-width: 1199px) {
    .filter-block {
        max-width: 600px;
    }
}

.price-title {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4D5163;
}

.filter-input-block {
    display: flex;
    margin-top: 4px;
}

.filter-input {
    max-width: 50%;
    z-index: 0;
    height: 41px;
    border: 1px solid #D9DDE6;
    outline: none;
}

.left-input {
    border: 1px solid #D9DDE6;
    border-radius: 4px 0 0 4px;
}

.filter-inputs {
    display: flex;
    max-width: 200px;
}
.right-input {
    border: 1px solid #D9DDE6;
    border-left: unset;
    border-radius: 0 4px 4px 0;
}
.object-count {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}
.object-count-button {
    cursor: pointer;
    border-radius: 5px;
    background: #0ca;
    color: #eef3fa;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    align-self: center;
    height: 50px;
    width: 50%;
    justify-content: center;
    font-size: 20px;
    border: unset;
}
@media screen and (min-width: 1700px) {
    .filter-block {
        max-width: 940px;
        min-height: 100vh;
    }
    .filter-inputs {
        max-width: unset;
    }
}
@media screen and (max-width: 1200px) {
    .filter-input-block {
        max-width: 270px;
    }
}
@media screen and (max-width: 991px) {
    .filter-block {
        max-width: 830px;
    }
    .filter-inputs {
        max-width: unset;
    }
}
@media screen and (max-width: 780px) {
    .filter-input-block {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 581px) {
    .filter-input {
        width: unset;
    }
    .area-params {
        margin-top: 22px;
    }
    .area-params-block {
        margin-top: 10px;
    }
    .room-params {
        margin-top: unset;
    }
}
@media screen and (max-width: 490px) {
    .filter-input-block {
        display: flex;
        flex-direction: column;
    }
    .currency-selector {
        margin-left: unset;
        margin-top: 10px;
    }
}