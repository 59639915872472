.main-search-page {
    width: 100%;
    margin: 0 auto;
    padding-left: 92px;
    padding-right: 92px;
}

.main-search-empty-text {
    margin-top: 20px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.header-search-block {
    display: flex;
    align-items: baseline;
}
.search-button-block {
    border: unset;
    background: unset;
    color: #0ca;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.search-buttons {
    display: flex;
    padding-right: 20px;
}
.search-button {
    cursor: pointer;
    background: #0ca;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 64px;
    padding: 5px 10px;
}

.search-input {
    width: 100%;
    max-width: 620px;
    height: 43px;
    margin-top: 16px;
    margin-left: 16px;
    border-radius: 4px;
    border: 1px solid #D9DDE6;
    outline: none;
}

.search-input::placeholder {
    color: #A0A6BF;
    font-size: 16px;
    line-height: 22px;
}
input {
    color: #4D5163;
    padding: 0 10px;
    font-size: 14px;
    line-height: 22px;
}
.search-input-button {
    cursor: pointer;
    background: #0ca;
    border: unset;
    margin-left: 4px;
    border-radius: 5px;
    width: 70px;
    height: 41px;
    padding: 5px 10px;
    color: #ffffff;
}
.search-input-filter {
    cursor: pointer;
    background: unset;
    color: #0ca;
    border: 1px solid #0ca;
    font-size: 14px;
    line-height: 22px;
    width: 70px;
    height: 40px;
    margin-left: 4px;
    border-radius: 4px;
    position: relative;
}

.search-input-filter span {
    border-radius: 10px;
    width: 20px;
    height: 22px;
    position: absolute;
    right: -10px;
    top: -10px;
    background: #f94f5d;
    color: #ffffff;
}

.link-search {
    color: #ffffff;
    text-align: center;
}

@media screen and (min-width: 1700px) {
    .search-input {
        max-width: 771px;
    }
}

@media screen and (max-width: 1200px) {
    .main-search-page {
        max-width: 1024px;
        padding-left: 32px;
        padding-right: 32px;
    }
    .search-input {
        max-width: 415px;
    }
}
@media screen and (max-width: 991px) {
    .search-input {
        max-width: 823px;
    }
}

@media screen and (max-width: 590px) {
    .header-search-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: unset;
        padding: 16px;
    }
    .search-input {
        margin-left: unset;
    }
    .search-input-filter {
        width: 100%;
    }
    .search-input-button {
        width: 100%;
    }
    .search-buttons {
        padding-right: unset;
        margin-top: 10px;
    }
}