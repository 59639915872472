.filter-tabs {
    margin-top: 32px;
    width: 100%;
    max-width: 780px;
}
@media screen and (min-width: 1700px) {
    .filter-tabs {
        max-width: 840px;
    }
}
@media screen and (max-width: 1199px) {
    .filter-tabs {
        max-width: 630px;
    }
}

.filter-tabs-list label {
    position: relative;
}

.filter-label-circle {
    content: '';
    width: 16px;
    height: 16px;
    background: #0ca url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljc1LjE5Yy4zLjI3LjM0Ljc1LjA2IDEuMDZsLTQgNC41YS43NS43NSAwIDAxLTEuMDkuMDNsLTIuNS0yLjVhLjc1Ljc1IDAgMTExLjA2LTEuMDZsMS45NCAxLjk0TDYuNjkuMjZBLjc1Ljc1IDAgMDE3Ljc1LjE4eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==) center center no-repeat;
    border: 2px solid #fff;
    border-radius: 8px;
    position: absolute;
    top: -6px;
    right: -6px;
}

.filter-tabs-title {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4D5163;
}
ul.filter-tabs-list {
    display: flex;
    flex-flow: wrap;
    list-style: none;
    padding: 0;
}
ul.filter-tabs-list li{
    display: inline;
}
ul.filter-tabs-list li label{
    display: inline-block;
    background-color: rgba(255, 255, 255, .9);
    border: 2px solid rgba(139, 139, 139, .3);
    color: #adadad;
    border-radius: 5px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
}

ul.filter-tabs-list li label {
    padding: 8px 12px;
    cursor: pointer;
    margin-right: 10px;
    color: #4D5163;
}

ul.filter-tabs-list li label::before {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    font-size: 12px;
    padding: 2px 6px 2px 2px;
    content: "";
    transition: transform .3s ease-in-out;
    width: 7px;
    height: 12px;
    display: none;
}

ul.filter-tabs-list li input[type="checkbox"]:checked + label::before {
    content: "";
    transition: transform .3s ease-in-out;
    width: 7px;
    height: 12px;
    border: 2px solid #ffffff;
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
}

ul.filter-tabs-list li input[type="checkbox"]:checked + label {
    border: unset;
    background-color: #0ca;
    color: #fff;
    transition: all .2s;
    margin-left: 5px;
}

ul.filter-tabs-list li input[type="checkbox"] {
    position: absolute;
    margin-left: 5px;
}
ul.filter-tabs-list li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    margin-left: 5px;
}
ul.filter-tabs-list li input[type="checkbox"]:focus + label {
    border: 2px solid rgba(139, 139, 139, .3);
}


.filter-tab-element label {
    display: inline-block;
    background-color: rgba(255, 255, 255, .9);
    border: 2px solid rgba(139, 139, 139, .3);
    color: #adadad;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
    cursor: pointer;
    padding: 8px 12px;
}

.filter-margin-top {
    margin-top: -32px;
}
