.currency-selector {
    width: 140px;
    height: 41px;
    border-radius: 4px;
    border: 1px solid #D9DDE6;
    margin-left: 4px;
    outline: none;
}
@media screen and (max-width: 780px) {
    .currency-selector {
        width: 50%;
        margin-left: unset;
    }
}