.room-params {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    max-width: 780px;
}

.room-params-title {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4D5163;
}

.room-params-block {
    max-width: 345px;
}

@media screen and (max-width: 1199px) {
    .room-params {
        max-width: 574px;
    }
    .room-params-block {
        max-width: 255px;
    }
}
@media screen and (max-width: 760px) {
    .room-params {
        flex-direction: column;
        margin-top: unset;
    }
}